export const API_SERVER = 'https://stage.api.totus.pro/backlot/v1';
export const API_SERVER_TIMEOUT = 60000;

export const API_ORIGIN_SERVER = 'https://stage.api.totus.pro';

export const API_GRAPHQL = API_SERVER + '/graphql';

export const MINGLO_SERVER = 'https://dev.hermes-api.voithrucorp.com';
export const MINGLO_SERVER_TIMEOUT = 5000;

export const JAMAKE_EDITOR = 'http://lab.dev.voithru.co.kr/editor';

export const GRAYLOG_SERVER = 'https://log.jamake.io';
export const GRAYLOG_SERVER_TIMEOUT = 5000;

export const LOGGER_SERVER = 'https://dev.voithru-logger.voithrucorp.com/';
export const LOGGER_SERVER_TIMEOUT = 5000;

export const SLACK_API_SERVER = 'https://slack.com';
export const SLACK_API_SERVER_TIMEOUT = 5000;

export const AWS_LOCATION = 'ap-northeast-2';
